<template>
  <img alt="WFP logo" src="./assets/logo.png">
  <br>
<!--  <MainPage msg="Welcome to WFP"/>-->
  <a href="#/privacy">Privacy Policy</a>
  <component :is="currentView" />
</template>

<script>
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import MainPage from './components/MainPage.vue'
const routes = {
  '/': MainPage,
  '/privacy': PrivacyPolicy
}
export default {
  name: 'App',
  components: {
    //HelloWorld,
    MainPage
  },
  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/']
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
