<template>
  <div class=WordSection1>

    <p align=center style='margin:0cm;text-align:center;line-height:115%'><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:14.0pt;
line-height:115%;font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>PRIVACY
POLICY</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;line-height:115%'><span lang=EN-US
                                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:
RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;line-height:115%'><span lang=EN-US
                                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:
RU'>Last Updated: July 01, 2021 with Effective Date July 01, 2021</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>WeFairPlay</span></span><span lang=EN-US
                                                     style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Inc</span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'> (&#698;we&#698;, &#698;us&#698;
or &#698;our&#698;) respects your privacy and understands that you care about
your personal information and how it is used. This Privacy Policy (&#698;Privacy
Policy&#698;) clarifies our privacy practices and describes how we collect,
use, disclose and otherwise process your personal information when you install
and/or use (&#698;use&#698;) </span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US'>one or more of our Mobile Apps (hereinafter, &#698;the
<span style='mso-bidi-font-weight:bold'>&#698;</span>App(s)<span
          style='mso-bidi-font-weight:bold'>&#698;</span> or <span style='mso-bidi-font-weight:
bold'>&#698;</span>our App(s)<span style='mso-bidi-font-weight:bold'>&#698;</span>)<span
          style='mso-bidi-font-weight:bold'>, and explains the rights and choices
available to you with respect to your personal information.</span></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>When we refer to personal information (or personal data) we mean any
information of any kind relating to you as an identified or identifiable
natural person. It is a natural person who can be identified directly or
indirectly, in particular by reference to an identification number or to one or
more factors specific to your physical, physiological, mental, economic,
cultural or social status. </span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>And namely personal information identifies, relates to, describes,
references, is capable of being associated with, or could reasonably be linked,
directly or indirectly, with you.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>Our Privacy Policy applies to all users, and others who access our Apps (&#698;Users&#698;).</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>PLEASE READ THE FOLLOWING PRIVACY POLICY, FOR INFORMATION REGARDING THE
WAYS YOUR PERSONAL INFORMATION MAY BE PROCESSED, AND YOUR CHOICES REGARDING
YOUR PERSONAL DATA, CAREFULLY. WHEN YOU USE THE APP(S), YOU ACKNOWLEDGE THAT
YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>For the purposes of the EU General Data Protection Policy (GDPR), we are
the data controller, unless otherwise stated.</span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:
EN-US'></span></b></p>

    <p style='margin:0cm;line-height:115%'><span lang=EN-US style='font-family:
"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;line-height:115%'><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US;mso-fareast-language:RU'>I. INFORMATION WE PROCESS</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>Depending on circumstances mentioned below, the following general
categories of personal information may be collected and otherwise processed:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <table class=MsoTableGrid border=0 cellspacing=0 cellpadding=0
           style='border-collapse:collapse;border:none;mso-yfti-tbllook:1184;mso-padding-alt:
 0cm 5.4pt 0cm 5.4pt;mso-border-insideh:none;mso-border-insidev:none'>
      <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
        <td width=28 valign=top style='width:21.3pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><b><span
              lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></b></p>
        </td>
        <td width=255 style='width:191.1pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><b><span
              lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Category</span></b></p>
          <p style='margin:0cm;text-align:justify;line-height:115%'><b><span
              lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></b></p>
        </td>
        <td width=340 style='width:254.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><b><span
              lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>General Examples</span></b></p>
          <p style='margin:0cm;text-align:justify;line-height:115%'><b><span
              lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></b></p>
        </td>
      </tr>
      <tr style='mso-yfti-irow:1'>
        <td width=28 valign=top style='width:21.3pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>A.</span></p>
        </td>
        <td width=255 valign=top style='width:191.1pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Identifiers</span></p>
        </td>
        <td width=340 valign=top style='width:254.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>A real name, postal code, unique personal
  identifier, online identifier, Internet Protocol address, email address, or
  other similar identifiers.</span></p>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></p>
        </td>
      </tr>
      <tr style='mso-yfti-irow:2'>
        <td width=28 valign=top style='width:21.3pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>B.</span></p>
        </td>
        <td width=255 valign=top style='width:191.1pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Geolocation Data</span></p>
        </td>
        <td width=340 valign=top style='width:254.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Physical location or movements.</span></p>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></p>
        </td>
      </tr>
      <tr style='mso-yfti-irow:3'>
        <td width=28 valign=top style='width:21.3pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>C.</span></p>
        </td>
        <td width=255 valign=top style='width:191.1pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Internet or <span class=GramE>other</span> similar
  network activity</span></p>
        </td>
        <td width=340 valign=top style='width:254.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Information on your interaction with application, or
  advertisement.</span></p>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>&nbsp;</span></p>
        </td>
      </tr>
      <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes'>
        <td width=28 valign=top style='width:21.3pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>D.</span></p>
        </td>
        <td width=255 valign=top style='width:191.1pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Commercial Information</span></p>
        </td>
        <td width=340 valign=top style='width:254.85pt;padding:0cm 5.4pt 0cm 5.4pt'>
          <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                          style='font-family:"Arial",sans-serif;color:black;background:white;
  mso-ansi-language:EN-US'>Records of personal property, products or services
  purchased, obtained, or considered, or other purchasing or consuming
  histories or tendencies.</span></p>
        </td>
      </tr>
    </table>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:
EN-US'>PLEASE, NOTE THAT:</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>We do not collect any special categories of personal data about you (this
includes details about your race or ethnicity, religious or philosophical
beliefs, sex life, sexual orientation, political opinions, trade union
membership, information about your health, and genetic and biometric data). Nor
do we collect any information about criminal convictions and offenses.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:
EN-US'>Moreover, we cannot access or use your credit card or debit card
information</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>. </span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>When the App includes in-app purchases our e-commerce provider (Google)
is responsible for billing, processing and charging for the in-app purchases,
handles your personal information and keeps it absolutely safe and secure. You
may access the applicable “in-app” purchase rules and policies directly from
Google Play store.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:
EN-US'>II. HOW IS YOUR PERSONAL DATA COLLECTED</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>We obtain the categories of personal information listed above from the
following categories of sources:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l14 level2 lfo14'><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:EN-US'><span style='mso-list:
Ignore'>2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Directly
from you:</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Guided by the principle of
data minimization we designed our Apps in a way that doesn't require you to
sign up for an account in order to use the App. It means that you do not need
to provide us with any information that can be used to access the App (like
full name, username, public username used on social media accounts, phone
number(s), e-mail address or other similar information), however, when you
decide to contact us using the e-mail or support form (for example to inquire
about the App or to request a support regarding the App usage) we may collect
and use whatever </span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>contact
information</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'> (name, e-mail address, and
any information that you provide, including the contents of the messages or
attachments) you send us via email or contact form <span
          style='mso-spacerun:yes'> </span>when it is necessary for adequate </span><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US'>performance of the contract</span></b><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;
mso-bidi-font-weight:bold'> between you and us.</span><span lang=EN-US
                                                            style='mso-ansi-language:EN-US'> </span><span lang=EN-US style='font-family:
"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>We use
such information to respond effectively to your inquiry, fulfill your requests,
and send you communications that you request.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l14 level2 lfo14'><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;mso-ansi-language:EN-US'><span style='mso-list:
Ignore'>2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Indirectly
from you (Information collected by automated means):</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>When you use the App, some
information about your mobile device and your user behavior may be processed
automatically. This information is generally non-personal, <span class=GramE>i.e.</span>
it does not, on its own, permit direct association with any specific
individual, and we may access it only in aggregated form. We process this
information on the ground of our </span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>legitimate
interest</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'> in improving our App and
giving our Users the best experience. If we do not access such data, we may not
be able to provide you with all the features of the App.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>We use third-party automatic
data processing technologies to analyze certain information sent by your device
via our Apps (advertising or analytics tools). Some of them launch automated
processing of your personal data, including profiling, which means any form of
automated processing of personal data used to evaluate certain personal aspects
relating to you, in particular to analyze or predict aspects concerning your
personal preferences, interests, behavior, location or movements (see the list
of data described below). Processing information through automatic data
processing technologies starts automatically when you launch the App.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>The following are the types
of information we collect indirectly from you:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US'>Device Information. </span></b><span lang=EN-US
                                                              style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>When you use a mobile device to access the App, some of details about
your device are reported, including “device identifiers”. Device identifiers
are small data files or similar data structures stored on or associated with
your mobile device, which uniquely identify your mobile device (but not your
personality). Device identifier enables generalized reporting or personalized
content and ads by our partners, service providers, and/or other third parties.</span><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US'></span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;line-height:115%'><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>The following data can be
processed:</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Information about the device
itself: type of your mobile device, type of operating system and its version,
model and manufacturer, screen size, screen density, orientation, battery,
device memory usage.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Information about the
internet connection: mobile carrier, network provider, network type, Internet
Protocol (“IP”) address, timestamp and duration of sessions, speed, browser.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Location-related
information: IP-address, the country code/region/state, city associated with
your SIM card or your device, (Designated Market Area (DMA) in US), language
settings, time zone.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Device identifiers: Advertising
ID or Android ID.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Information
about the App. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Its name, API key
(identifier for the App), version, properties of our App can be reported for automated
processing and analysis. </span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Cookies
and similar technologies. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>When you use the App,
cookies and similar technologies may be used (pixels, web beacons, scripts). A
cookie is a text file containing small amounts of information which is downloaded
to your device when you access the App. The text file is then sent back to the
server each time you use the App. This enables us to operate the App more
effectively. For example, we will know how many users access specific areas or
features within our App and which links or ads they clicked on. We use this
aggregated information to understand and optimize how our App is used, improve
our marketing efforts, and provide content and features that are of interest to
you. We may ask advertisers or other partners to serve ads or services to the
App, which may use cookies or similar technologies.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Log
file information. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Log file information is
automatically reported each time you make a request to access the App. It can
also be provided when the App is installed on your device. When you use our
App, analytics tools automatically record certain log file information,
including time and date when you start and stop using the App, and how you
interact with the App.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>Ad-related
information.</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'> The following data might be
reported about the ads you can view: the date and time a particular ad is
served; a record if that ad was “clicked” or if it was shown as a
“conversation” event; what ad offer is about; what type of ad it is (e.g.,
text, image, or video); which ad placement is involved (where the ad offer is
displayed within the App); whether you respond to the ad.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:54.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l8 level1 lfo3'><span
        lang=ru-BY style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:#0C00;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b style='mso-bidi-font-weight:normal'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US'>In-app
events.</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'> When you use our App,
analytics tools automatically record in-app information (tutorial steps,
payments, in-app purchases, custom events).</span><span lang=ru-BY
                                                        style='font-family:"Arial",sans-serif;mso-ansi-language:#0C00;mso-bidi-font-weight:
bold'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:18.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'>Information provided
automatically to advertising or analytics tools does not generally come to our
control, therefore we cannot be responsible for processing such information.
Please mind that some services are engaged in personal data profiling and may
obtain information related to your personality and/or your device by using
technologies that do not belong to our scope of responsibility. We do not
control, supervise or stand surety for how the third parties process your
personal data, that might be collected by their own means (not through our
App). Any information request regarding the disclosure of your personal
information should be directed to such third parties (see Section IV).</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b style='mso-bidi-font-weight:normal'><span lang=ru-BY style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:#0C00;mso-fareast-language:RU'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:RU'>III. THE
PURPOSES OF PROCESSING YOUR PERSONAL DATA</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Our mission
is to constantly improve our App and provide you with new experiences. As part
of this mission, we use your information for the following purposes:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l2 level1 lfo5'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US;mso-fareast-language:
RU;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>(a)<span
        style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><b
        style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US;mso-fareast-language:RU'>To provide the App, maintain,
improve, test and monitor the effectiveness of our App. </span></b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:
RU;mso-bidi-font-weight:bold'>We use information you provide to us directly and
information that is processed automatically to provide and deliver the App to
you as well as to better understand User behavior and trends, detect potential
outages and technical issues, to support the existing functions of the App and
to add new features and services to the App.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:RU;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l2 level1 lfo5'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US;mso-fareast-language:
RU;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>(b)<span
        style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>To provide you with
interest-based (behavioral) advertising or other targeted content</span></b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>. We may use information that
is processed automatically<b> </b>for marketing purposes (to show ads that may
be of interest to you based on your preferences). We provide personalized
content and information to you, which can include online ads or other forms of
marketing.</span><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:EN-US;mso-fareast-language:RU;mso-bidi-font-weight:bold'></span></p>

    <p class=MsoListParagraphCxSpMiddle><b><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l2 level1 lfo5'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US;mso-fareast-language:
RU'><span style='mso-list:Ignore'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><b><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>To
communicate with you. </span></b><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>We
may use information that is processed automatically to send you push
notifications with technical notices, updates, security alerts, and support and
administrative messages. Similarly, we </span><span lang=EN-US
                                                    style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#262626;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>use your contact information
to respond to you when you contact us.</span><span lang=EN-US style='font-size:
12.0pt;line-height:107%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-ansi-language:EN-US;
mso-fareast-language:RU'></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoListParagraph style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l2 level1 lfo5'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US;mso-fareast-language:
RU;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>(d)<span
        style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></span><b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>To prevent fraud and spam, to
enforce the law</span></b><span lang=EN-US style='font-size:12.0pt;line-height:
115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>.
We are really aimed at our App to be free of spam and fraudulent content so
that you feel safe and free. We may use your information to prevent, detect,
and investigate fraud, security breaches, potentially prohibited or illegal
activities, protect our trademarks and enforce our Terms of Use. </span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:
RU;mso-bidi-font-weight:bold'></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-ansi-language:
EN-US;mso-fareast-language:RU;mso-bidi-font-weight:bold'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>If any new
purposes for processing your personal data arise, we will let you know we start
to process information on that other purpose by introducing the corresponding
changes to this Privacy Policy.</span><span lang=ru-BY style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:#0C00;mso-fareast-language:RU'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;mso-ansi-language:EN-US;mso-bidi-font-weight:
bold'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:RU'>IV. INFORMATION
SHARING AND DISCLOSURE</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>We
disclose your personal information to the following categories of recipients
for a variety of business purposes and only in the ways and contexts that are
described in this Privacy Policy:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l16 level1 lfo16'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:Symbol;
mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Third-party service providers</span></b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>. We work with
third-party companies to help us provide you the App and to otherwise assist us
in the operation of the App and improving it. These legal entities provide us
with automatic data processing technologies for the App and deliver data
processing, payment processing, and other services to us concerning the App,
and may be involved in the other types of services and activities otherwise
discussed in this Privacy Policy. That is why the App may contain tracking and
data collection and analytics tools and other automatic data processing
technologies for the App provided to us by such legal entities, and that is why
third-party companies may have access to and collect data and information about
you (within the categories of personal information mentioned above), such as
your device identifier, Advertising ID or Android ID, Designated Market Area
(DMA), geo-location information, IP address, application usage, access and
session times, and your viewing of and interactions with advertising, provided
and delivered as described in further detail below.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>We will not rent or sell your personal data to
any of the above-mentioned recipients, but we may share your information from
tools like cookies, log files, and device identifiers and location data, with
them. </span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><b><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>We may also share some of the information that
is processed automatically with <b>advertising partners</b> who distribute
advertising in the App. This information allows third-party advertising
networks, inter alia, to deliver targeted advertisements that are believed to
be of most interest for you.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;line-height:115%'><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Please note that while integrating external tools and
services we choose legal entities that can assure they apply all necessary
technical and organizational measures to protect personal data. However, we
cannot guarantee the security of any information transmitted from us to any
such legal entity. We are not responsible for any accidental loss or
unauthorized access to your personal data through a fault of such legal
entities. These legal entities are separately responsible for their privacy
policies and practices.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;line-height:115%'><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>We do not control or influence the above-mentioned
automatic data processing technologies or how they may be used. If you want to
know more about the above-mentioned third-party service providers and their
privacy <span class=GramE>options</span> please see the correspondent websites
and privacy policies. We are not responsible for any usage of your personal
data by the abovementioned legal entities contrary to our instructions.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><b><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>Please note that all third-party service
providers as well as advertising partners that are engaged in processing user
data are service providers that use such information on the basis of agreement
and pursuant to business purpose.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;line-height:115%'><b><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;color:black;background:
white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;line-height:115%'><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>A list of legal entities in this category of
recipients mentioned above and links to their privacy policies can be found in
the </span><span lang=RU><a href="#Appendix_A"><b><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>Appendix A</span></b></a></span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> below.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>In
addition to the above, we may disclose your personal information if needed for
objective reasons, due to public interest or in other unforeseen circumstances:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>- as
required by law;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>- when
we believe in good faith that disclosure is necessary to protect our rights,
protect your safety or the safety of others, investigate fraud, or respond to a
government request;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>- if
we are involved in a merger, acquisition, or sale of all or a portion of its
assets, you will be notified via email and/or a prominent notice in our App of
any change in ownership or uses of your personal information, as well as any
choices you may have regarding your personal information.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>It
means that your personal information may also be disclosed to the following
categories of recipients:</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l16 level1 lfo16'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:Symbol;
mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Government and law
enforcement officials, courts. </span></b></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l16 level1 lfo16'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:Symbol;
mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Authorized Agents </span></b><span lang=EN-US
                                                            style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(for example, when you
decide to designate an authorized agent to submit requests on your behalf).</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l16 level1 lfo16'><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:Symbol;
mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;color:black;
mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Affiliates. </span></b><span lang=EN-US
                                                      style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(<span class=GramE>for</span>
example, when corporate audit, analysis and consolidated reporting, or a
compliance with applicable laws are needed).</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>Please,
note that the App may include links to third-party websites/services</span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;mso-ansi-language:EN-US'>, </span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>plug-ins and applications
(for example, allowing Users to share certain content and information on social
media platforms), or you may access the App from a third-party site. Clicking
on those links or enabling those connections may allow third parties to collect
or share data about you.</span><span lang=EN-US style='font-size:12.0pt;
line-height:115%;mso-ansi-language:EN-US'> </span><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>We do not control these
third-party websites/services, plug-ins and applications, and are not
responsible</span><span lang=EN-US style='font-size:12.0pt;line-height:115%;
mso-ansi-language:EN-US'> </span><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>for the privacy practices or the content of these
third-party websites/services, plug-ins and applications</span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;mso-ansi-language:EN-US'> </span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>linked to or from our
App, including the information or content contained within them. We encourage
you to read the privacy notice of every website you visit/service, plug-in and
application you use.<span style='background:yellow;mso-highlight:yellow'></span></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>V. INTERNATIONAL DATA TRANSFERS</span></b><span lang=EN-US
                                                       style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We work in the international space and provide our App to our Users
around the world.</span><span lang=EN-US style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We and legal entity that provide automatic data processing technologies
for the App or our third-party advertising partners may transfer the
automatically processed information across borders and from your country or
jurisdiction to other countries or jurisdictions around the world.</span><span
        lang=EN-US style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Please note that we may transfer information, including personal data,
to a country and jurisdiction that does not have the same data protection laws
as in your jurisdiction.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>This means that your personal information can be transferred to a third
country, a territory or one or more specified sectors within that third
country, or to the international organization where data protection and
confidentiality regulations may not provide the same level of protection of a
personal data as your country does.</span><span lang=EN-US style='mso-ansi-language:
EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We try to make sure that the recipient of any personal data provides a
proper protection of the personal data received, in accordance with the current
legislation on the protection of such information. By using the App, you agree
that we may transfer your personal data to any third country, a territory or
one or more specified sectors within that third country, or to the
international organization.</span><span lang=EN-US style='mso-ansi-language:
EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>For the purposes of data storage, we recourse to the services of the
hosting organizations. We take your privacy seriously and, therefore, encrypt
your personal data – if possible - before sending it to the hosting organizations
for the purposes of its storage. Please note that we cooperate only with those
hosting organizations that have passed our security and reliability check.</span><span
        lang=EN-US style='mso-ansi-language:EN-US'></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>VI. HOW LONG WE USE YOUR PERSONAL DATA</span></b><span lang=EN-US
                                                              style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We generally retain your personal information for as long as is
necessary for performing the functional service of the App and to comply with
our legal obligations. If you no longer want us to use your information that we
physically access and store, you can request that we erase your personal
information. </span><span lang=EN-US style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>However, some data may still be stored for a certain time period (but no
longer than the storage purpose requires) if information is necessary to comply
with legal obligation (taxation, accounting, audit) or in order to maintain
safety and data backup settings, prevent fraud or other malicious acts.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>VII. SECURITY</span></b><span lang=EN-US style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>The security of your personal information is important to us. We follow
generally accepted industry standards to protect the personal information
submitted to us, both during transmission and once we receive it.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We take reasonable and appropriate measures to protect personal
information from loss, misuse and unauthorized access, disclosure, alteration
and destruction, taking into account the risks involved in the processing and
the nature of the personal information.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We implement appropriate technical and organizational measures, which
are designed to implement data-protection principles, such as data
minimization, in an effective manner and to integrate the necessary safeguards
into the processing. We seek your personal data to be encrypted with proper and
strong encryption algorithms, including hashing where possible.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Unfortunately, no method of transmission over the Internet, or method of
electronic storage, is 100% secure. We do our best to protect your personal
data, nevertheless, we cannot guarantee its absolute security. In the event
that your personal information is compromised as a breach of security, we will
promptly notify you in compliance with applicable law.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If you have any questions about the security of our App, you can contact
us through the email displayed below.</span><span lang=EN-US style='mso-ansi-language:
EN-US'></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
12.0pt;line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>VIII. CHILDREN’S PRIVACY</span></b><span lang=EN-US style='mso-ansi-language:
EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Our App and the services our App <span class=GramE>provides</span> are
not directed to children under the age of 18 (or older if required in an
applicable jurisdiction to comply with applicable laws). Therefore, we do not
knowingly collect or solicit any personal information from children under 18
(or older if required in an applicable jurisdiction to comply with applicable
laws). No one under age 18 (or older if required in an applicable jurisdiction
to comply with applicable laws) may provide any personal information to the
App. If you are under 18 (or older if required in an applicable jurisdiction to
comply with applicable laws), then <b>DO NOT DOWNLOAD OR USE the App</b>.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If we learn that we have collected personal information from a child
under age 18 (or older if required in an applicable jurisdiction to comply with
applicable laws), we will erase that information as quickly as possible. If you
believe that we might have any information from or about a child under 18 (or
older if required in an applicable jurisdiction to comply with applicable laws),
please </span><span lang=RU><a href="mailto:wefairplaygame@gmail.com"><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'>contact us</span></a></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>IX. YOUR
RIGHTS AND CHOICES</span></b><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:RU'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If you are a resident of the European Economic Area, your rights to
access, edit and delete your information can be found in clause </span><span
        lang=RU><a href="#EAA"><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>9.1.</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'> of this section.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If you are a California resident, your rights to access, edit and delete
can be found in clause </span><span lang=RU><a href="#California"><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'>9.2.</span></a></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> of this section.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If you are not a resident of the EEA or California, depending on your
location, you may be able make requests to access, correct and/or delete
certain personal information that you provide to us. For your protection, we
may require proof and verification of identity and jurisdiction of residency
before we can answer the above requests. If you wish to make such a request,
you may contact us at <b>wefairplaygame@gmail.com</b>. If we change or delete your
personal information or if you decline to actively share certain personal
information with us, we may not be able to provide to you some of the features
and functionality of the App. Once we have verified you, we will honor such
requests at our discretion and in accordance with applicable law.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><a name=EAA><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>9.1. Information for Individuals in the European Economic Area (EEA)</span></b></a></p>

    <span style='mso-bookmark:EAA'></span>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>If you
reside in the European Economic Area (&quot;EEA&quot;), then applicable privacy
laws (in particular GDPR) give you certain rights regarding your personal data.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>According to
GDPR you have the following options in relation to your personal data that was
collected:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <ul style='margin-top:0cm' type=disc>
      <li class=MsoNormal style='color:black;margin-bottom:0cm;text-align:justify;
     line-height:115%;mso-list:l11 level1 lfo1;tab-stops:list 36.0pt;
     background:white;vertical-align:baseline'><b><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>Data Access and Portability. </span></b><span
          lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>You can request copies of your personal
     information.</span><span lang=EN-US style='font-size:12.0pt;line-height:
     115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-ansi-language:EN-US;mso-fareast-language:RU'></span></li>
      <li class=MsoNormal style='color:black;margin-bottom:0cm;text-align:justify;
     line-height:115%;mso-list:l11 level1 lfo1;tab-stops:list 36.0pt;
     background:white;vertical-align:baseline'><b><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>Change or Correct Data. </span></b><span
          lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>You have the right to ask us to correct,
     change, update or rectify your data.</span><span lang=EN-US
                                                      style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;
     mso-fareast-language:RU'></span></li>
      <li class=MsoNormal style='color:black;margin-bottom:0cm;text-align:justify;
     line-height:115%;mso-list:l11 level1 lfo1;tab-stops:list 36.0pt;
     background:white;vertical-align:baseline'><b><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>Data Retention and Deletion. </span></b><span
          lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>The User data is generally retained for as
     long as it is needed to provide you the App. However, specific retention
     times can vary based on context of the processing performed. You have the
     right to ask to delete all or some of the personal data that is held about
     you.</span><span lang=EN-US style='font-size:12.0pt;line-height:115%;
     font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-ansi-language:EN-US;mso-fareast-language:RU'></span></li>
      <li class=MsoNormal style='color:black;margin-bottom:0cm;text-align:justify;
     line-height:115%;mso-list:l11 level1 lfo1;tab-stops:list 36.0pt;
     background:white;vertical-align:baseline'><b><span lang=EN-US
                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>Restriction of Processing. </span></b><span
          lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";background:white;mso-ansi-language:
     EN-US;mso-fareast-language:RU'>Under certain circumstances, you may have
     the right to limit the ways in which your personal information is used.</span><span
          lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-ansi-language:EN-US;
     mso-fareast-language:RU'></span></li>
    </ul>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>To
exercise any of the rights described above, you can contact us through the [</span><span
        lang=RU><a href="http://wefairplay.com/support/"><span lang=EN-US
                                                                            style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>contact form</span></a></span><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>]. Please bear in mind
that we ensure the above-mentioned rights only with respect to the information
that we physically access and store.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>When
your personal information that is processed automatically you may object to
such processing in some circumstances. Where your personal information is
processed for direct marketing purposes, you may ask to cease processing your
data for these direct marketing purposes. In order to exercise your right
please contact the recipients listed in the Section IV of this Privacy Policy
to learn how you can object to processing your data. Most of them have clear
instructions on their privacy pages, functional API or other options.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>If you
don’t want us to share device identifiers and geolocation data with service providers,
please check your device settings to opt out as described </span><span lang=RU><a
        href="#Opt_Out"><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>below
&gt;&gt;</span></a></span><span lang=EN-US style='font-size:12.0pt;line-height:
115%;font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>If you
are located in the European Union, you may address our representative when you
have questions on privacy issues:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>Albina
<span class=SpellE>Gulevich</span> <span class=SpellE>Apolskaya</span>, Office:
35, 205 Holland Park Avenue, W11 4XB, London, e-mail: <span class=SpellE>user.<span
          class=GramE>data.protection</span></span> @ gmail.com</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;mso-add-space:auto;text-align:justify;text-indent:-18.0pt;
line-height:115%;mso-list:l9 level2 lfo17'><a name=California><b><span
        lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US'><span
        style='mso-list:Ignore'>9.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><b><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Information for Residents of California: Your California
Privacy Rights</span></b></a></p>

    <span style='mso-bookmark:California'></span>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>The California
Consumer Privacy Act of 2018 (CCPA) provides those who reside in the State of
California with specific rights regarding their personal information. This
section describes your CCPA rights and explains how to exercise those rights.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>The CCPA provides consumers (California residents) with the following
rights regarding their personal information:</span><span lang=ru-BY
                                                         style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
#0C00'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Right to Access.</span></b><span lang=EN-US
                                                          style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> You have the right to request that we disclose, no more than twice in a
twelve-month period, for any of the following for the period that is twelve
months prior to the request date:</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>The categories of
personal information we have collected about you.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>The categories of sources
for the personal information we have collected about you.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>The business or
commercial purpose for our collecting or selling your personal information.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>The categories of third
parties to whom we share your personal information.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:72.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>The specific pieces of
personal information we have collected about you.</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-left:72.0pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l15 level1 lfo4'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
mso-fareast-font-family:Arial;color:black;mso-ansi-language:EN-US;mso-fareast-language:
RU'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Whether we have sold or disclosed your Personal
Information for a business purpose, and if so, </span><span lang=EN-US
                                                            style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>two separate lists disclosing:</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:128.15pt;mso-add-space:auto;text-align:justify;
text-indent:-18.0pt;mso-list:l7 level1 lfo10'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Courier New";
mso-fareast-font-family:"Courier New";color:black;mso-ansi-language:EN-US'><span
        style='mso-list:Ignore'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>sales, identifying the categories of personal information
that we have sold about you in the prior 12 months as well as the categories of
third parties to whom we sold that personal information, by category or
categories of personal information for each category of third parties to whom
your personal information was sold; and</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:128.15pt;mso-add-space:auto;text-align:justify;
text-indent:-18.0pt;mso-list:l7 level1 lfo10'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Courier New";
mso-fareast-font-family:"Courier New";color:black;mso-ansi-language:EN-US'><span
        style='mso-list:Ignore'>o<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>disclosures for a business purpose, identifying the
categories personal information that we have disclosed about you in the prior
12 months as well as the categories of third parties to whom we disclosed your
personal information, by category or categories of personal information for
each category of third parties to whom your personal information was disclosed.</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.45pt;text-align:justify'><span lang=EN-US style='font-size:12.0pt;
line-height:107%;font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Once we receive and confirm your verifiable consumer
request, data outlined in this section above (Right to Access) will be
disclosed to you.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=ru-BY
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
#0C00'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Deletion rights. </span></b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>You have the right to request that we delete any of
your personal information that we collected from you and retained, subject to
certain exceptions</span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:#0C00'> </span><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>outlined in the CCPA. Once we receive and confirm your verifiable consumer
request, we will delete (and direct our service providers to delete) your
personal information from our records, unless an exception applies.<b></b></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Right to Opt-Out (“Do Not Sell”). </span></b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>You have the right to tell us not to disclose or
transfer your personal information to a third party in exchange for something
of value.</span><span lang=EN-US style='mso-ansi-language:EN-US'> </span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>The CCPA refers to this as your right to say “Do Not
Sell” my personal information. </span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><b><span lang=EN-US style='font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>We do not sell personal
information, as noted herein.</span><span lang=EN-US style='mso-ansi-language:
EN-US'> </span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>If we decide to sell
personal information in the future, we will post an appropriate notice and
opt-out method, and we will not sell any personal information previously
collected.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>However, if you don’t
want us to process your personal information any <span class=GramE>more</span>
please contact us through the [</span><span lang=RU><a
        href="http://wefairplay.com/support/"><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>contact
form</span></a></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>]. In most cases there is
no way to maintain the App’s further operating without functional data
therefore you will be advised to remove the App from your device. </span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>If you don’t want us to
share device identifiers and geolocation data with third-party service <span
          class=GramE>providers</span> please check your device settings as described </span><span
        lang=RU><a href="#Opt_Out"><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>below <b>&gt;&gt;</b></span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Non-discrimination. </span></b><span lang=EN-US
                                                              style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>You are entitled to exercise the rights described in this section free
from discrimination. This means that we will not discriminate against you in a
manner prohibited by the CCPA because you exercise your CCPA rights.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Unless permitted by the
CCPA, we will not:</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l17 level1 lfo11'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Deny you goods or
services.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l17 level1 lfo11'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Charge you different
prices or rates for goods or services, including through granting discounts or
other benefits, or imposing penalties.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l17 level1 lfo11'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Provide you a different
level or quality of goods or services.</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l17 level1 lfo11'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Suggest that you may
receive a different price or rate for goods or services or a different level or
quality of goods or services.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Now, when you aware of your California privacy rights, please note, the
following:</span></b></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>All the categories of
personal information listed in Section I of this Privacy Policy</span><span
        lang=EN-US style='mso-ansi-language:EN-US'> &#698;</span><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>I. INFORMATION WE PROCESS&#698; has been collected by us from Users within
the last twelve (12) months.<b></b></span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>In the preceding twelve
(12) months, we have disclosed your personal information included in each of
categories of personal information A, B, C and D from listed in Section I of
this Privacy Policy &#698;I. INFORMATION WE PROCESS&#698;</span><span
        lang=EN-US style='mso-ansi-language:EN-US'> </span><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>for each business purpose described above depending on your use of the
App described herein.<b></b></span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l13 level1 lfo9'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-ansi-language:EN-US;mso-bidi-font-weight:bold'><span
        style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>We are not in the
business of selling information about you to anybody, which indicates that no
personal information of yours has been sold ever.<b></b></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>How to Exercise Your California Privacy Rights</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>To request access to your personal information or request deletion,
please submit a verifiable consumer request to us by either:</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l1 level1 lfo20'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>[</span><span lang=RU><a
        href="http://wefairplay.com/support/"><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>contact
form</span></a></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>];</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l1 level1 lfo20'><span
        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:Arial;
color:black;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>e-mail: wefairplaygame@gmail.com</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Consumers may exercise these rights via an authorized agent who meets
the agency requirements of the CCPA. Only you or a person registered with the
California Secretary of State that you authorize to act on your behalf, may
make a verifiable consumer request related to your personal information. You
may also make a verifiable consumer request on behalf of your minor child.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Please, include the wording “Consumer rights to maintain confidentiality
in the state of California”</span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:#0C00'> </span><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>in the text of your request.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;mso-color-alt:windowtext;
background:white;mso-ansi-language:EN-US'>Any request you submit to us is
subject to an identification and residency verification process, so when
submitting a verifiable request, you should be ready to:</span><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l0 level1 lfo12'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;mso-color-alt:
windowtext;background:white;mso-ansi-language:EN-US'>Provide sufficient
information that allows us to reasonably verify you are the person about whom
we collected personal information or an authorized representative, which may
include: name, address, city, state, and zip code and email address. We may use
this information to surface a series of security questions to you to verify
your identity. If you are making a request through an authorized agent acting
on your behalf, such authorized agent must provide written authorization
confirming or a power of attorney, signed by you.</span><span lang=EN-US
                                                              style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'></span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;line-height:115%'><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;
text-align:justify;text-indent:-18.0pt;line-height:115%;mso-list:l0 level1 lfo12'><span
        lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;mso-color-alt:
windowtext;background:white;mso-ansi-language:EN-US'>Describe your request with
sufficient detail that allows us to properly understand, evaluate, and respond
to it. </span><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:black;mso-color-alt:windowtext;background:white;mso-ansi-language:EN-US'>We
will not be able to respond to your request or provide you with personal
information if we cannot: (<span class=SpellE>i</span>) verify your identity or
authority to make the request; or (ii) confirm the personal information relates
to you. We may ask you for additional information or documents to verify your
identity. We may also carry out checks, including with third party identity
verification services, to verify your identity before taking any action with
your personal information. This is regarded as a safeguard measure to prevent
disclosure of your personal information under a fake or scum request.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>We ensure that personal
information provided in a verifiable consumer request will be used only to
verify the requestor’s identity or authority to make the request and not for
any other purpose. We will keep it for the adequate term reasonably needed for
the purpose described above and delete after the purpose is fulfilled.<span
          style='mso-spacerun:yes'>   </span></span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
        lang=EN-US style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif;
color:black;mso-color-alt:windowtext;background:white;mso-ansi-language:EN-US'>We
try to respond to a verifiable consumer request within forty-five (45) days of
its receipt. If we require more time, we will inform you of the reason and
extension period in writing. Please note that we are only required to respond
to two requests per customer each year.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><a name="Opt_Out"><b><span lang=EN-US style='font-size:12.0pt;line-height:
115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>X.
HOW TO OPT OUT</span></b></a></p>

    <span style='mso-bookmark:Opt_Out'></span>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Opt-out of
marketing tracking</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>If you don’t
want third-party service providers to use data concerning you to personalize
ads on the basis of your interests you should choose option “<span
          class=SpellE>Opt</span> out of Ad Personalization” on your Android device in
Settings/ Google/ Ads.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>You can also visit
https://youradchoices.com for the same purpose. (Please, bear in mind that we
have no control over this web site and any decisions it provides).</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Please mind
when you opt out of certain interest-based advertising, you may still continue
to receive contextual ads based on other non-personal information, such as ads
related to the content of other digital products you are using.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Opt-out of
Location Data Processing</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>If you don’t
want third-party service providers to use your precise location data, or
street-level location information about you, you should turn Location off for
the App via the menu “Settings &gt; Apps &gt; [our App] &gt; Permissions &gt;
Location”. Then turn off the “Location” button.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Also, as
mentioned above, we may occasionally send you push notifications or alerts to
inform you about certain offers, news and tips concerning the App even if our
App is not currently open or in use. If you want to opt-out from receiving
these types of communications, you can manage your push notification
preferences or disable these notifications by turning off the notification
settings in the settings of your mobile device any time. Bear in mind that if
you do that, the App may lose full functionality or we will not be able to
provide you any important notifications like privacy notices.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>It should be
noted that you can stop all information collection regarding your App usage by
uninstalling the App using the standard uninstall process for your device. If
you uninstall the App from your mobile device, some information concerning you
may still be stored as described in Section VI above.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><b><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>XI. CHANGES TO THE PRIVACY POLICY</span></b><span lang=EN-US
                                                         style='mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>We recognize that information privacy is an ongoing responsibility, and
so we will from time to time update this Privacy Policy as we undertake new
personal data practices or adopt new privacy policies.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Whenever we change this Privacy policy, we will post those changes to this
Privacy Policy and other places that we consider appropriate. Additional forms
of notice of modifications or updates as appropriate under the circumstances
may be provided to you.<b></b></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='mso-ansi-language:EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>XII. HOW TO CONTACT US</span></b><span lang=EN-US style='mso-ansi-language:
EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>If you have any questions about this Privacy Policy, please feel free to
contact us via [</span><span lang=RU><a
        href="http://wefairplay.com/support/"><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>contact
form</span></a></span><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>] or sending your message
at <b>wefairplaygame@gmail.com</b>.</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=ru-BY
                                                                    style='mso-ansi-language:#0C00'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>Copyright ©
2021 <span class=SpellE>WeFairPlay</span> Inc</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;line-height:115%'><span lang=EN-US
                                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;line-height:115%'><span lang=EN-US
                                                                        style='font-size:12.0pt;line-height:115%;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>&nbsp;</span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><a name="Appendix_A"><span lang=EN-US style='font-size:12.0pt;
line-height:115%;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>APPENDIX
A</span></a><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'></span></p>

    <p class=MsoNormal align=right style='margin-bottom:0cm;text-align:right;
line-height:115%'><span lang=EN-US style='font-size:12.0pt;line-height:115%;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-ansi-language:EN-US;mso-fareast-language:RU'>to
the Privacy Policy</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>The following legal entities may be involved in the processing of your
data (you can use hyperlinks below to learn more about how each of the entities
handles your data):</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>AdColony</span></b></span><b><span lang=EN-US
                                                            style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>, Inc. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(<a name="_Hlk65144335">U.S.A.</a>)
- Ad Management Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://www.adcolony.com/privacy-policy/"><span lang=EN-US
                                                              style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.adcolony.com/privacy-policy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Admob</span></b></span><b><span lang=EN-US
                                                         style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> (Google LLC) </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(U.S.A.) - <a
        name="_Hlk65143394">Ad Management Service Provider</a></span></p>

    <span style='mso-bookmark:_Hlk65143394'></span>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><a name="_Hlk66790649"></a><span
        class=SpellE><span style='mso-bookmark:_Hlk66790649'><b><span lang=EN-US
                                                                      style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>AppLovin</span></b></span></span><span style='mso-bookmark:_Hlk66790649'><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'> Corporation </span></b></span><span style='mso-bookmark:
_Hlk66790649'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(U.S.A.) -<b> </b>Ad
Management Service Provider</span></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span
        style='mso-bookmark:_Hlk66790649'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>Privacy Policy: </span></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span
        style='mso-bookmark:_Hlk66790649'></span><a
        href="https://www.applovin.com/privacy/"><span style='mso-bookmark:_Hlk66790649'><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'>https://www.applovin.com/privacy/</span></span></a><span
        style='mso-bookmark:_Hlk66790649'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'></span></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span
        style='mso-bookmark:_Hlk66790649'></span><a
        href="https://dash.applovin.com/assets/pdf/dpa.pdf"><span style='mso-bookmark:
_Hlk66790649'><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>https://dash.applovin.com/assets/pdf/dpa.pdf</span></span></a><span
        style='mso-bookmark:_Hlk66790649'><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'></span></span></p>

    <span style='mso-bookmark:_Hlk66790649'></span>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>AppsFlyer</span></b></span><b><span lang=EN-US
                                                             style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Inc. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(U.S.A.) - Analytics
Service Provider<b></b></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://www.appsflyer.com/privacy-policy/"><span lang=EN-US
                                                               style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.appsflyer.com/privacy-policy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Bytedance</span></b></span><b><span lang=EN-US
                                                             style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Pte. Ltd. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(Singapore) - Ad
Management Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><a name="_Hlk65143673"><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Privacy Policy: </span></a></p>

    <span style='mso-bookmark:_Hlk65143673'></span>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'><a href="https://www.pangleglobal.com/privacy">https://www.pangleglobal.com/privacy</a></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.pangleglobal.com/policy-center"><span lang=EN-US
                                                                style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.pangleglobal.com/policy-center</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Facebook Inc.</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (U.S.A.) - Analytics
Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.facebook.com/privacy/explanation"><span lang=EN-US
                                                                  style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.facebook.com/privacy/explanation</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.facebook.com/about/privacy/"><span lang=EN-US
                                                             style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.facebook.com/about/privacy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Fyber</span></b></span><b><span lang=EN-US
                                                         style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> N.V</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (Germany) - Ad
Management Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.fyber.com/services-privacy-statement/"><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.fyber.com/services-privacy-statement/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.fyber.com/ccpa-resource/"><span lang=EN-US style='font-family:
"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.fyber.com/ccpa-resource/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.fyber.com/gdpr-faqs/"><span lang=EN-US style='font-family:
"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.fyber.com/gdpr-faqs/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>GameAnalytics</span></b></span><b><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Ltd</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (UK) - Analytics Service
Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy:</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://gameanalytics.com/privacy/"><span lang=EN-US style='font-family:
"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://gameanalytics.com/privacy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Google LLC</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (U.S.A.) - Analytics
Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://firebase.google.com/support/privacy"><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://firebase.google.com/support/privacy</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>InMobi</span></b></span><b><span lang=EN-US
                                                          style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Pte Ltd</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (India) - <a
        name="_Hlk65144033">Ad Management Service Provider</a></span></p>

    <span style='mso-bookmark:_Hlk65144033'></span>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://www.inmobi.com/privacy-policy/"><span lang=EN-US
                                                            style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.inmobi.com/privacy-policy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>ironSource</span></b></span><b><span lang=EN-US
                                                              style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> Mobile Ltd.</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (Israel) - Ad Management
Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://www.ironsrc.com/ru/mediation/"><span lang=EN-US style='font-family:
"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://www.ironsrc.com/ru/mediation/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=RU><a
        href="https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/"><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'>https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Berkeley Payment Solutions</span></b><span lang=EN-US style='font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'>
(Canada) - Ad Management Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://berkeleypayment.com/privacy-policy/"><span lang=EN-US
                                                                 style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://berkeleypayment.com/privacy-policy/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></b></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Unity Technologies, Inc.</span></b><span lang=EN-US style='font-family:
"Arial",sans-serif;color:black;background:white;mso-ansi-language:EN-US'> (U.S.A.)
- Analytics / Ad Management Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><a name="_Hlk65144277"><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Privacy Policy:</span></a><span lang=EN-US
                                                         style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'> </span><span lang=RU><a href="https://unity3d.com/legal/privacy-policy"><span
        lang=EN-US style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:
EN-US'>https://unity3d.com/legal/privacy-policy</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span class=SpellE><b><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'>Vungle</span></b></span><b><span lang=EN-US
                                                          style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>, Inc. </span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'>(U.S.A.) - Ad Management
Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://vungle.com/privacy/"><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'>https://vungle.com/privacy/</span></a></span><span
        class=MsoHyperlink><span lang=EN-US style='font-family:"Arial",sans-serif;
background:white;mso-ansi-language:EN-US'></span></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><b><span lang=EN-US
                                                                       style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>YANDEX, LLC</span></b><span lang=EN-US style='font-family:"Arial",sans-serif;
color:black;background:white;mso-ansi-language:EN-US'> (Russia) - Analytics
Service Provider</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>Privacy Policy: </span><span lang=RU><a
        href="https://yandex.com/legal/confidential/"><span lang=EN-US
                                                            style='font-family:"Arial",sans-serif;background:white;mso-ansi-language:EN-US'>https://yandex.com/legal/confidential/</span></a></span><span
        lang=EN-US style='font-family:"Arial",sans-serif;color:black;background:white;
mso-ansi-language:EN-US'></span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

    <p style='margin:0cm;text-align:justify;line-height:115%'><span lang=EN-US
                                                                    style='font-family:"Arial",sans-serif;color:black;background:white;mso-ansi-language:
EN-US'>&nbsp;</span></p>

  </div>

</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
